import { makeStyles, Theme, createStyles, Card, CardHeader, CardContent, CardMedia, IconButton } from '@material-ui/core'
import LaunchIcon from '@material-ui/icons/Launch'
import * as React from 'react'

type RegistryItemProps = {
    nameOfItem: string
    imageUrl: string
    message: string
    monzoAmount: number
    monzoMessage: string
    itemUrl: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            maxWidth: '425px',
            width: '425px',
            marginBottom: '5vh'
        },
        media: {
            height: 140
        }
    })
)

const RegistryItem: React.FC<RegistryItemProps> = ({
    nameOfItem,
    imageUrl,
    message,
    monzoAmount,
    monzoMessage,
    itemUrl
}: RegistryItemProps) => {
    const classes = useStyles()
    return (
        <Card className={classes.card}>
            <CardMedia className={classes.media} image={imageUrl} title={nameOfItem} />
            <CardHeader
                title={nameOfItem}
                action={
                    monzoAmount ? (
                        <IconButton href={`https://monzo.me/dcb/${monzoAmount}?d=${monzoMessage}`}>
                            <LaunchIcon />
                        </IconButton>
                    ) : (
                        <IconButton href={itemUrl}>
                            <LaunchIcon />
                        </IconButton>
                    )
                }
            />
            <CardContent>{message}</CardContent>
        </Card>
    )
}

export default RegistryItem
